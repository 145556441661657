import './../css/HomePage.css';
import React from 'react';
import { Alert } from 'react-bootstrap';


class PaginaNonTrovata extends React.Component {

  render() {

    return (
    <div className="PaginaNonTrovata">
      <Alert variant='warning'>
        Attenzione, non è stata trovata la pagina richiesta.
      </Alert>
    </div>
    );
  }
}

export default PaginaNonTrovata;