import React from 'react';
import './../../css/Login.css';
import {ArrowRightCircle } from 'react-bootstrap-icons';
import {Cart } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';

function WishList(props) {
    return (
            <div className="login" >
                <div className="login_container" ><ArrowRightCircle className="chiudiWishList" onClick= {props.closeCarrello}/>
                    <h3>Login</h3>
                    
                </div>
            
            </div>

            );
};

export default WishList;