import './../../css/Card.css';
import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

class CardProdottiHomePage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
                <Card style={{width: '18rem'}}>
                <Card.Img variant="top" src={this.props.srcImmagine} />
                <Card.Body>
                    <Card.Title>{this.props.nome}</Card.Title>
                    <Card.Text>
                        {this.props.descrizione}
                    </Card.Text>
                    <Button variant="danger">{this.props.azione}</Button>
                </Card.Body>
                </Card>

                );
    }
}

export default CardProdottiHomePage;