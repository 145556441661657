import React from 'react';
import './../../css/Carrello.css';
import {ArrowRightCircle } from 'react-bootstrap-icons';
import {Cart } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';

function WishList(props) {
    return (
            <div className="carrello" >
                <div className="leftBarraCarrello" onClick= {props.closeCarrello}></div>
                <div className="barraCarrello" ><ArrowRightCircle className="chiudiWishList" onClick= {props.closeCarrello}/>
                    <h3>Carrello</h3>
                    <div className="carrello-empty">
                        <p className="empty">
                        <div className="iconaCarrello">
                            <Cart  />
                        </div>
                        <div className="carrelloRow">Nessun prodotto nel carrello</div>                       
                        <div className="carrelloRow"><Button variant="outline-danger" onClick= {props.closeWishList}>RITORNA AL NEGOZIO</Button>
                        </div>
                        </p>
                    </div>
                </div>
            
            </div>

            );
};

export default WishList;