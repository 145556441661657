import React from 'react';


import { Navbar } from 'react-bootstrap';

import {ArrowLeft } from 'react-bootstrap-icons';
import {Person } from 'react-bootstrap-icons';
import {Cart } from 'react-bootstrap-icons';
import {BalloonHeart } from 'react-bootstrap-icons';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

//class CardHomePage extends React.Component {
function Navigator(props) {
    return (
            <Navbar bg="light" expand="lg">
                <Container fluid>
                    <Navbar.Brand>
                        <div className="logo-wrapper">
                            <a className="logo nasa-logo-retina" >
                                <img src={props.srcLogo} alt="Logo" />
            
                            </a>                        
                        </div>
                        {
                            props.currentPage !== props.previousPage ?
                            <ArrowLeft onClick= {props.tornaIndietro} /> :
                                    ""

                        }
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="me-auto my-2 my-lg-0"
                            style={{maxHeight: '100px'}}
                            navbarScroll
                            >
                            <Nav.Link href="#">Home</Nav.Link>
                            <Nav.Link href="#chi_sono">Chi sono</Nav.Link>
                            <NavDropdown title="Prodotti" id="navbarScrollingDropdown">
                                <NavDropdown.Item href="#fiocchi_nascita">Fiocchi Nascita</NavDropdown.Item>
                                <NavDropdown.Item href="#palestrine">
                                    Palestrine
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#varie">
                                    Oggetti vari
                                </NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="#" disabled>
                                Carrello
                            </Nav.Link>
                        </Nav>
                        <Form className="d-flex">
                            <Form.Control
                                type="search"
                                placeholder="Ricerca nel sito"
                                className="me-2"
                                aria-label="Ricerca nel sito"
                                />
                            <Button variant="outline-success">Cerca</Button>
                        </Form>
                        <div className="iconeHeader"><Person onClick= {props.openLogin} /></div>
                        <div className="iconeHeader"><Cart onClick= {props.openCarrello} /></div>
                        <div className="iconeHeader"><BalloonHeart onClick= {   props.openWishList} /></div>
            
            
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            );

}

export default Navigator;