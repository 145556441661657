import React from 'react';
import HomePage from './HomePage';
import PaginaNonTrovata from './PaginaNonTrovata';

class RouterComponent extends React.Component {



    handleKeyUp(value) {
        this.props.searchIntoDispensa(value);
    }

    renderPage(page) {
        switch (page) {
            case 'homePage':
                return <HomePage srcFiocco={this.props.srcFiocco} srcPalestrina={this.props.srcPalestrina} 
                srcLogoLeMieCreazioni={this.props.srcLogoLeMieCreazioni}
        
          />;
          break;
            
            default:
                return <PaginaNonTrovata />;
        }
    }

    render() {
        return (
                <div className="render-component">
                    {this.renderPage(this.props.currentPage)}
                </div>
                );
    }
}

export default RouterComponent;