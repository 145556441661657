export function PostData(type, userData){
    let BaseUrl = 'https://www.susannataje.it/api/';
    
    return new Promise((resolve, reject) => {
        fetch(BaseUrl + type + '.php', {
            method: 'POST',
            body: JSON.stringify(userData)
        })
            .then((response) => response.json())
            .then((responseJson) => {
                resolve(responseJson);
            })
            .catch((error) => {
               reject(error);
            });
    });
}