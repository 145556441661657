import './../css/HomePage.css';
import React, {useState} from 'react';
import {useEffect} from 'react';
import CardProdottiHomePage from "./../Components/CardProdottiHomePage/CardProdottiHomePage"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

function HomePage(props) {
    const [listaProdotti, setListaProdotti] = useState([
        {
            srcImmagine: props.srcFiocco,
            nome: "Fiocchi nascita",
            descrizione: "Guarda tutti i fiocchi nascita creati da me. Sceglie uno oppure divertiti a crearlo insieme a me!",
            azione: "Vai al negozio"
        },
        {
            srcImmagine: props.srcPalestrina,
            nome: "Palestrine",
            descrizione: "Guarda tutti i modelli di palestrine per far giocare il tuo bimbo!",
            azione: "Vai al negozio"
        },
        {
            srcImmagine: props.srcPalestrina,
            nome: "Altri prodotti",
            descrizione: "Guarda tutti gli altri prodotti! Ed aiutami a ideare nuove creazioni",
            azione: "Vai al negozio"
        }
    ]);

    useEffect(() => {

    }, []);

    return (
            <div className="homepage">
                <div className="prodotti">
                    <Container>
                        <Row>
                            <Col><h4 className="titoloProdotti"> <img src={props.srcLogoLeMieCreazioni} alt="Logo" /><br></br><br></br><div className="hidden">Naviga tra tutti i miei prodotti</div></h4></Col>
                        </Row>
                        <Row>
                            {
                                listaProdotti.map((prodotto, key) => {
                                    return <Col>
                                    <CardProdottiHomePage 
                                        srcImmagine = {prodotto.srcImmagine}
                                        nome = {prodotto.nome}
                                        descrizione = {prodotto.descrizione}
                                        azione = {prodotto.azione}
                                        key = {key}
                                        />
                                    </Col>;
                                })
                            }
                        </Row>
                    </Container>
                </div>
                <div className="footer">
                </div>
            </div>
            );
}

export default HomePage;