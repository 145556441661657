module.exports = Object.freeze({
  LIST_OF_CARDS: [
        {
          nameCard: "Menù Settimanale",
          page: "menu_settimanale",
          textCard: "programma cosa devi mangiare questa settimana attingendo dalle tue ricette e dalla dispensa!"
        },
        {
          nameCard: "Le mie ricette",
          page: "le_mie_ricette",
          textCard: "Sei tu lo chef! Crea le tue personalissime e squisitissime ricette!"
        
        },
        {
          nameCard: "Dispensa",
          page: "dispensa",
          textCard: "Controlla e sistema sempre la tua Dispensa! Non vorrai restare senza cibo!"
        }
      ],
   MONTHS: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"]
});