import React from 'react';
import Cookies from 'js-cookie';
import {PostData} from './services/PostData'
import {GetData} from './services/GetData'

import logo from './img/logo.svg';
import logoSusanna from './img/logoSusanna.png';
import logoLeMieCreazioni from './img/logoLeMieCreazioni.png';

import fiocco from './img/fiocco.jpg';
import palestrina from './img/palestrina.jpg';

import './css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Navigator from './Components/Navigator/Navigator';
import WishList from './Components/WishList/WishList';
import Carrello from './Components/Carrello/Carrello';
import Login from './Components/Login/Login';
import ConstantsList from './Constants';
import RouterComponent from './pages/RouterComponent';


class App extends React.Component {
    constructor(props) {
        super(props);

        this.effettuaLogin = this.effettuaLogin.bind(this);
        this.logout = this.logout.bind(this);

        const getAccessToken = () => Cookies.get('access_token');

        const today = new Date();
        const months = ConstantsList.MONTHS;
        const mese = months[today.getMonth()];

        this.state = {
            currentPage: 'homePage',
            previousPage: 'homePage',
            mostraWishList: false,
            mostraCarrello: false,
            mostraLogin: false,
            user: [],
            listOfDispensa: [],
            listOfCardsMenu: [],
            giorno: today.getDate(),
            anno: today.getFullYear(),
            notification: 'il menù di oggi prevede -> Colazione: - Pranzo: - Cena: ',
            errorMessage: '',
            listOfCards: ConstantsList.LIST_OF_CARDS,
            selectedCardMenu: 0,
            jwt: getAccessToken(),
            isAuthenticated: getAccessToken() !== undefined
        };
    }

    tornaIndietro() {
        this.setState({currentPage: this.state.previousPage});
        this.setState({previousPage: this.state.currentPage});
    }
    
    openWishList() {
        this.setState({mostraWishList: true});
    }
    closeWishList() {
        this.setState({mostraWishList: false});
    }
    openCarrello() {
        this.setState({mostraCarrello: true});
    }
    closeCarrello() {
        this.setState({mostraCarrello: false});
    }
    openLogin() {
        this.setState({mostraLogin: true});
    }
    closeLogin() {
        this.setState({mostraLogin: false});
    }
    
      changePage(currentPage) {
        this.setState({currentPage: currentPage});
        this.setState({previousPage: this.state.currentPage});
    }
    
    effettuaLogin = async (loginForm) => {
        try {
            PostData('login', loginForm).then((result) => {
                let responseJson = result;
                const tokens = {access_token: responseJson.jwt};
                const expires = (60 * 60 * 24 * 30) * 1000;
                const inOneMonth = new Date(new Date().getTime() + expires);
                Cookies.set('access_token', tokens.access_token, {expires: inOneMonth});


                window.location.reload();
                console.log(responseJson);
            });


        } catch (error) {
            console.log(error);
        }
    }

    logout = () => {
        try {
            Cookies.remove('access_token');
            window.location.reload();
        } catch (error) {
            console.log(error);
            
        }
    }
    
    render() {
        return (
                <div className="App">
                    <header className="MyApp-header">
                        <Navigator 
                            currentPage={this.state.currentPage || ""} 
                            previousPage={this.state.previousPage || ""}
                            tornaIndietro = {this.tornaIndietro.bind(this)}
                            openWishList = {this.openWishList.bind(this)}
                            openCarrello = {this.openCarrello.bind(this)}
                            openLogin = {this.openLogin.bind(this)}
                            srcLogo = {logoSusanna}
                
                            />
                    </header>
                    <div className="app-container">
                                        <RouterComponent 
                                            {...this.state} 
                                            changePage={this.changePage.bind(this)} 
                                            srcFiocco={fiocco} 
                                            srcPalestrina={palestrina} 
                                            srcLogoLeMieCreazioni ={logoLeMieCreazioni}
                                            />
                                    </div>
                                    {this.state.mostraWishList ? <WishList closeWishList={this.closeWishList.bind(this)}/> : null}
                                    {this.state.mostraCarrello ? <Carrello closeCarrello={this.closeCarrello.bind(this)}/> : null}
                                    {this.state.mostraLogin ? <Login closeLogin={this.closeLogin.bind(this)}/> : null}
                </div>
                );
    }
}

export default App;