import React from 'react';

import {ArrowRightCircle } from 'react-bootstrap-icons';
import {Heart } from 'react-bootstrap-icons';

import Button from 'react-bootstrap/Button';



class WishList extends React.Component {

    returnPreviousButton() {
        console.log(this.props)
    }

    render() {
        return (
                <div className="wishList" >
                    <div className="leftBarraWishList" onClick= {this.props.closeWishList}></div>
                    <div className="barraWishList" ><ArrowRightCircle className="chiudiWishList" onClick= {this.props.closeWishList}/>
                        <h3>WishList</h3>
                        <div className="wishlist-empty">
                            <p className="empty">
                            <div className="heartWishListEmpty">
                                <Heart  />
                            </div>
                            <div className="wishListRow">Nessun prodotto nella lista dei desideri. </div>                       
                            <div className="wishListRow"><Button variant="outline-danger" onClick= {this.props.closeWishList}>RITORNA AL NEGOZIO</Button>
                            </div>
                            </p>
                        </div>
                    </div>
                
                </div>

                );
    }
}
;



export default WishList;